import React from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import Quizhome from "../Quiezhome/Quizhome";
import Rough from "../Roughslider/Rough";
import HomeHero from "../Startingheropage/Homehero";
import Lheader from "../Landingpageheader/Lheader";
import Cookies from 'js-cookie';
import ResultPage from "../ResultPage/ResultPage";
import config from '../../config.json';
import DetailResultPage from "../DetailResultPage/DetailResultPage";
import MyProfile from "../MyProfile/MyProfile";
import Login from "../Loginpage/Login";
import { LoadingPage } from "../LoadingPage/LoadingPage";
import PrivacyPolicy from "../PrivacyPolicy/PrivacyPolicy";
import TermsCondition from "../TermsCondition/TermsCondition";
import CancellationRefund from "../CancellationRefund/CancellationRefund";
import ShippingDelivery from "../ShippingDelivery/ShippingDelivery";
import QuizInstruction from "../QuizInstruction/QuizInstruction";

const Routingpage = () => {
  return (
    <>
      {/* <Router> */}
      <Routes>
        <Route path="/" element={<HomeHero />} />
        <Route path="/login" element={<Login />} />
        <Route path="/Lheader" element={<Lheader />} />
        <Route path="/home" element={<Quizhome />} />
        <Route path="/result-page" element={<ResultPage />} />
        <Route path="/my_profile" element={<MyProfile />} />
        <Route path="/detail_result_page" element={<DetailResultPage />} />
        <Route path="/rough" element={<Rough />} />
        <Route path="/logout" element={<Logout />} />
        <Route path="/privacy_policy" element={<PrivacyPolicy />} />
        <Route path="/terms_conditon" element={<TermsCondition />} />
        <Route path="/cancellation_refund_policy" element={<CancellationRefund />} />
        <Route path="/shipping_delivery_policy" element={<ShippingDelivery />} />
        <Route path="/instruction" element={<QuizInstruction />} />

      </Routes>
      {/* </Router> */}
    </>
  );
};

const Logout = () => {
  Cookies.remove('token', { domain: config.cookieDomain });
  Cookies.remove('JSESSIONID', { domain: config.cookieDomain });
  Cookies.remove('role', { domain: config.cookieDomain });
  Cookies.remove('username', { domain: config.cookieDomain });
  Cookies.remove('picture', { domain: config.cookieDomain });
  Cookies.remove('isTestAttended', { domain: config.cookieDomain });
  window.location.href = config.domain + '/logout';
  return (
    <>
      <LoadingPage />
    </>);
}

export default Routingpage;
