import React, { useState, useRef, useEffect } from "react";
import Layout from "../Layout/Layout";
import "./Rough.css";
import { useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { selectedLang, testResult } from "../../utils/recoil";
import Cookies from "js-cookie";
import { Toast } from "primereact/toast";
import config from "../../config.json";
import { LoadingPage } from "../LoadingPage/LoadingPage";

const Rough = () => {
  const [questions, setQuestions] = useState([]);
  const setTestResult = useSetRecoilState(testResult);
  const tokenCookie = Cookies.get('token', { domain: config.cookieDomain });
  const isPaidCookie = Cookies.get('role', { domain: config.cookieDomain });
  const isTestTaken = Cookies.get('isTestAttended', { domain: config.cookieDomain });
  const usernameCookie = Cookies.get('username', { domain: config.cookieDomain });
  const navigate = useNavigate();
  const toast = useRef(null);
  const [loading, setLoading] = useState(true);
  const [selectedOptions, setSelectedOptions] = useState(
    Array(questions.length).fill(null)
  );
  const [clickedButtons, setClickedButtons] = useState(
    Array(questions.length).fill(null)
  );
  const [opacity, setOpacity] = useState(0);
  const questionRefs = useRef(Array(questions.length).fill(null));

  useEffect(() => {
    if (tokenCookie) {
      if (isPaidCookie && (isPaidCookie === 'ROLE_UNPAID_USER')) navigate('/home');
      if (isTestTaken && (isTestTaken === 'TRUE')) navigate('/my_profile');
    } else {
      window.location.href = config.domain + '/login';
    }
    let user = JSON.parse(localStorage.getItem('user'));
    let items;
    let clickedBtns;
    if (user && usernameCookie in user) {
      items = user[usernameCookie]?.item;
      clickedBtns = user[usernameCookie]?.clickedButtons;
    }

    if (items && clickedBtns) {
      setSelectedOptions(items);
      setOpacity(items?.length);
      setClickedButtons(clickedBtns);
      // questionRefs.current[items?.length].scrollIntoView({
      //   behavior: "smooth",
      // });
    }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (tokenCookie) {
      let questionsFromLocal = JSON.parse(localStorage.getItem('questions'));
      if (questionsFromLocal?.length) {
        setQuestions(questionsFromLocal);
        setLoading(false);
      } else {
        fetch(config.domain + '/questions', {
          credentials: 'include'
        })
          .then(res => res.json())
          .then(res1 => {
            let data = [...res1.data].sort(() => Math.random() - 0.5);
            console.log(data);
            // let data = [...res1.data].slice(0, 5);
            data.push({ id: res1.data?.length + 1, en: "Select Gender." });
            setQuestions(data);
            localStorage.setItem('questions', JSON.stringify(data));
            setLoading(false);
          })
          .catch(err => console.log("error: " + err))
      }
    }
  }, []);

  const lang = useRecoilValue(selectedLang);

  const [result, setResult] = useState([]);

  const handleSubmit = () => {
    const answeredQuestionsCount = selectedOptions.filter(option => option).length;
    if (answeredQuestionsCount === questions.length - 1) {
      setLoading(true);
      const rawResponse = fetch(config.domain + '/submit-response', {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          data: selectedOptions
        })
      }).then(res => res.json())
        .then(res1 => {
          // console.log("submit response: ", res1.data)
          const userDetails = {
            [usernameCookie]: {
              result: res1.data
            }
          }
          localStorage.setItem('user', JSON.stringify(userDetails));
          setLoading(false);
          navigate('/result-page', { state: { resultData: res1.data } })
        })
        .catch(err => {
          console.log("error: " + err);
          toast.current.show({ severity: 'error', summary: 'Submission Failed', detail: 'Failed to submit the test', life: 3000 });
        });
    }
    else {
      toast.current.show({ severity: 'warn', summary: 'Warning', detail: 'please attend all questions', life: 3000 });
    }
  }

  // State to keep track of selected option for each question


  // State to keep track of clicked status for each button

  // Ref for questions

  // Function to handle button click
  const handleButtonClick = (questionIndex, optionIndex, qid) => {
    // Update the selected option for the question
    const newSelectedOptions = [...selectedOptions];
    newSelectedOptions[questionIndex] = { id: qid, choice: optionIndex };
    setSelectedOptions(newSelectedOptions);
    setOpacity(questionIndex + 1);

    // Update the clicked status for the button
    const newClickedButtons = [...clickedButtons];
    newClickedButtons[questionIndex] = optionIndex;
    setClickedButtons(newClickedButtons);
    const userDetails = {
      [usernameCookie]: {
        item: newSelectedOptions,
        clickedButtons: newClickedButtons
      }
    }
    localStorage.setItem('user', JSON.stringify(userDetails));
    // Scroll to the next question if it exists
    if (questionIndex < questions.length - 1) {
      questionRefs.current[questionIndex + 1].scrollIntoView({
        behavior: "smooth",
      });
    }
  };

  // Function to handle gender selection
  const handleGenderSelection = (event) => {
    // Update selected options for all questions
    // const updatedSelectedOptions = selectedOptions;
    // updatedSelectedOptions[questions.length - 1] = {
    //   gender: event.target.value,
    // };
    // // setSelectedOptions(updatedSelectedOptions);
    // // Update opacity to show all questions
    // setOpacity(questions.length);
    // // Scroll to the last question
    // questionRefs.current[questions.length - 1].scrollIntoView({
    //   behavior: "smooth",
    // });
  };

  // Calculate completion percentage
  const completedQuestions = selectedOptions.filter(option => option).filter(
    (option, index) =>
      index !== questions.length - 1 && option !== null && option !== ""
  ).length;
  const totalQuestions = questions.length - 1; // Excluding gender question from total
  const completionPercentage = (completedQuestions / totalQuestions) * 100;

  return (
    <Layout>
      {loading ? (
        <LoadingPage />
      ) : (
        <>
          <Toast ref={toast} />
          {tokenCookie &&
            <div className="personality-container ">
              <div className="hidden">
                <div className="personality-heading">
                  <h1>
                    Free Personality Test by{" "}
                    <span style={{ color: "#1C75BC" }}>Uslot</span>
                  </h1>
                </div>
              </div>
              <div className="quistion-main-box ">
                <div
                  className="loader"
                  style={{ width: `${completionPercentage}%` }}
                ></div>
                <div className="complete-box">
                  <h1>{completionPercentage.toFixed(0)}% completed</h1>
                </div>
                <div className="quistons-main scrollbar-hide">
                  {/* Mapping questions */}
                  {questions.map((question, questionIndex) => (
                    <div
                      className="q-t "
                      key={questionIndex}
                      ref={(el) => (questionRefs.current[questionIndex] = el)}
                    >
                      <div
                        className={`main-2  ${questionIndex !== opacity && "opacity"
                          }`}
                      >
                        <div className="q-t-heading">
                          <h1 className="fade-in">{question[lang.code]}</h1>
                        </div>
                      </div>
                      <div className="answer-button">
                        {/* Add onClick event handler to all buttons */}
                        {questionIndex !== questions.length - 1 ? (
                          <>
                            <h2
                              className={`disagree-button ${questionIndex !== opacity && "op"
                                }`}
                            >
                              Disagree
                            </h2>
                            <div
                              className={`bigroundgreen3-button  ${questionIndex !== opacity && "opa"
                                } ${clickedButtons[questionIndex] === 1
                                  ? "clicke"
                                  : ""
                                } size-6`}
                              onClick={() =>
                                handleButtonClick(questionIndex, 1, question.id)
                              }
                            ></div>
                            <div
                              className={`bigroundgreen2-button  ${questionIndex !== opacity && "opa"
                                } ${clickedButtons[questionIndex] === 2
                                  ? "clicke"
                                  : ""
                                } size-5`}
                              onClick={() =>
                                handleButtonClick(questionIndex, 2, question.id)
                              }
                            ></div>
                            <div
                              className={`biground4-button  ${questionIndex !== opacity && "opa"
                                } ${clickedButtons[questionIndex] === 3
                                  ? "clicked"
                                  : ""
                                } size-4`}
                              onClick={() =>
                                handleButtonClick(questionIndex, 3, question.id)
                              }
                            ></div>
                            <div
                              className={`biground3-button  ${questionIndex !== opacity && "opa"
                                } ${clickedButtons[questionIndex] === 4
                                  ? "clicked"
                                  : ""
                                } size-3`}
                              onClick={() =>
                                handleButtonClick(questionIndex, 4, question.id)
                              }
                            ></div>
                            <div
                              className={`biground2-button ${questionIndex !== opacity && "opa"
                                } ${clickedButtons[questionIndex] === 5
                                  ? "clicked"
                                  : ""
                                } size-2`}
                              onClick={() =>
                                handleButtonClick(questionIndex, 5, question.id)
                              }
                            ></div>
                            <h2
                              className={`agree-button ${questionIndex !== opacity && "op"
                                }`}
                            >
                              Agree{" "}
                            </h2>

                          </>
                        ) : (
                          <div className="gender-dropdown">
                            <select onChange={handleGenderSelection}>
                              <option value="">Select Gender</option>
                              <option value="male">Male</option>
                              <option value="female">Female</option>
                            </select>
                            <div className="arrow-icon">&#9660;</div>
                          </div>
                        )}
                      </div>
                      {questionIndex !== questions.length - 1 &&
                        <div className="mobile-view-buttons">
                          <div className="agree-disagree-buttons">
                            <h2>Disagree</h2>
                            <h2>Agree</h2>
                          </div>
                        </div>
                      }
                    </div>
                  ))}
                  {/* End of mapped questions */}
                  <div className="complete-button">
                    <button onClick={handleSubmit}>Finish</button>
                  </div>
                </div>
              </div>
            </div>
          }
        </>
      )}
    </Layout>
  );
};

export default Rough;
