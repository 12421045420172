import React from 'react'
import { GoDotFill } from "react-icons/go";
import Layout from '../Layout/Layout';
import './PrivacyPolicy.css'
const PrivacyPolicy = () => {
    return (
        <Layout>
            <div className='PrivacyPolicy px-[6rem] py-[5rem] flex flex-col gap-10 md-max:px-[2rem] sm-max:px-[1rem] sm-max:py-[2rem] sm-max:gap-5'>
                <p className='para'>Welcome to Uslot.ai, your trusted platform for career prediction and guidance. Protecting your privacy is of utmost importance to us. This Privacy Policy outlines how we collect, use, and safeguard your personal information when you use our website or services.</p>
                <p>By accessing or using USlot.ai, you agree to the terms of this Privacy Policy. If you do not agree with any part of this Privacy Policy, please do not use our website or services.</p>
                <div className='contents'>
                    <p className='sub-heading'>1. Information We Collect</p>
                    <p className='point'><span><GoDotFill /></span>Personal Information: When you create an account with USlot.ai, we may collect personal information such as your name, email address, and demographic details. We only collect this information with your consent and use it to personalize your experience and provide tailored career predictions and recommendations.</p>
                    <p className='point'><span><GoDotFill /></span>Usage Information: We automatically collect certain information when you use our website, including your IP address, browser type, device information, and usage patterns. This information helps us improve our services, diagnose technical issues, and analyze user trends.</p>
                    <p className='point'><span><GoDotFill /></span>Cookies: We use cookies and similar tracking technologies to enhance your experience on USlot.ai. Cookies are small files stored on your device that enable us to recognize your preferences and track your interactions with our website. You can adjust your browser settings to refuse cookies, but this may limit certain features of our website.</p>
                </div>
                <div className='contents'>
                    <p className='sub-heading'>2. How We Use Your Information</p>
                    <p>We use the information we collect for the following purposes:</p>
                    <p className='point'><span><GoDotFill /></span>Providing Services: We use your personal information to deliver personalized career predictions, recommendations, and other services based on your preferences and career goals.</p>
                    <p className='point'><span><GoDotFill /></span>Communications: We may send you emails, notifications, or other communications regarding your account, updates to our services, or promotional offers. You can opt out of marketing communications at any time by following the unsubscribe instructions included in the emails.</p>
                    <p className='point'><span><GoDotFill /></span>Analytics: We analyze usage data to improve our website, optimize user experience, and develop new features and functionalities.</p>
                    <p className='point'><span><GoDotFill /></span>Legal Compliance: We may use your information to comply with applicable laws, regulations, or legal processes, or to protect our rights, property, or safety, or the rights, property, or safety of others.</p>
                </div>
                <div className='contents'>
                    <p className='sub-heading'>3. Information Sharing</p>
                    <p >We do not sell, rent, or trade your personal information with third parties for their marketing purposes. However, we may share your information with trusted third-party service providers who assist us in operating our website, conducting business, or servicing you, as long as those parties agree to keep this information confidential.</p>
                    <p>We may also disclose your information in response to a subpoena, court order, or other legal process, or to protect our rights, property, or safety, or the rights, property, or safety of others.</p>
                </div>
                <div className='contents'>
                    <p className='sub-heading'>4. Data Security</p>
                    <p>We take reasonable measures to protect your personal information from unauthorized access, disclosure, alteration, or destruction. However, no method of transmission over the internet or electronic storage is 100% secure, and we cannot guarantee absolute security.</p>
                </div>
                <div className='contents'>
                    <p className='sub-heading'>5. Children's Privacy</p>
                    <p>USlot.ai is not intended for use by individuals under the age of 18. We do not knowingly collect personal information from children under 18. If you are a parent or guardian and believe that your child has provided us with personal information, please contact us immediately so that we can take appropriate action.</p>
                </div>
                <div className='contents'>
                    <p className='sub-heading'>6. Changes to this Privacy Policy</p>
                    <p>We reserve the right to update or modify this Privacy Policy at any time. Any changes will be effective immediately upon posting the revised Privacy Policy on our website. We encourage you to review this Privacy Policy periodically for any updates.</p>
                </div>
                <div className='contents'>
                    <p className='sub-heading'>7. Contact Us</p>
                    <p>If you have any questions, concerns, or feedback about this Privacy Policy or our privacy practices, please contact us at <a className='text-blue-500' href="mailto:uslotedu@gmail.com">uslotedu@gmail.com</a></p>
                    <p>Thank you for trusting USlot.ai with your career aspirations. We are committed to protecting your privacy and providing you with valuable insights to help you succeed in your professional journey.</p>
                </div>

            </div>
        </Layout>
    )
}

export default PrivacyPolicy