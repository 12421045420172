import React, { useEffect, useState } from "react";

// import { Tab } from "@headlessui/react";
import { GoDotFill } from "react-icons/go";
import UserReview from "./UserReview";
import img from "../../Assets/cel-img.png";
import YourResultSection from "./YourResultSection";
import ResultChart from "./ResultChart";
import { FiChevronRight, FiChevronLeft } from "react-icons/fi";
const DetailResultTabSection = ({ item }) => {
  const [popupView, setPopupView] = useState(false);

  const [tabIndex, setTabIndex] = useState(0);

  const getArrayValues = (data) => {
    const traitsArray = data?.split(/\\r\\n|\\n|:/);
    const traits = [];
    if (traitsArray && traitsArray.length) {
      for (let i = 0; i < traitsArray.length; i += 2) {
        traits.push(
          <div key={i} className="flex gap-1 mb-2">
            <div>
              <span>
                <GoDotFill size={12} className="mt-[5px] " />
              </span>
            </div>
            <p className="text-xl sm-max:text-base">
              <span className=" font-bold">{traitsArray[i]}:</span>
              {traitsArray[i + 1]}
            </p>
          </div>
        );
      }
    }
    return traits;
  };

  const getArrayParaValues = (data) => {
    const traitsArray = data?.split(/\\r\\n/);
    const traits = [];
    if (traitsArray && traitsArray.length) {
      for (let i = 0; i < traitsArray.length; i++) {
        const traitsPointArray = traitsArray[i]?.split(/:/);
        if (traitsPointArray.length == 1)
          traits.push(
            <div key={i} className="flex gap-1 mb-2">
              <p className="text-xl sm-max:text-base">{traitsArray[i]}</p>
            </div>
          );
      }
    }
    return traits;
  };

  const getArrayPointValues = (data) => {
    const traitsArray = data?.split(/\\r\\n/);
    const traits = [];
    if (traitsArray && traitsArray.length) {
      for (let i = 0; i < traitsArray.length; i++) {
        const traitsPointArray = traitsArray[i]?.split(/:/);
        if (traitsPointArray.length > 1)
          traits.push(
            <div key={i} className="flex flex-col gap-1 mb-2">
              <span className=" font-bold text-xl">{traitsPointArray[0]}</span>
              <p className="text-xl sm-max:text-base">{traitsArray[i]}</p>
            </div>
          );
      }
    }
    return traits;
  };

  const tabData = [
    "Introduction",
    "Strength and Weakness",
    "Career Path",
    "Workplace Habit",
    "Conclusion",
    "Celebrity",
  ]
  useEffect(() => {
    if (tabIndex == 4) {
      setTimeout(() => {
        setPopupView(true)
      }, 3000);
    }
  }, [tabIndex])
  return (
    <div>
      <div className="w-full  px-2 py-16 sm:px-0 sm-max:py-10 sm-max:px-4">
        {/* <Tab.Group> */}
        <div className="flex w-full md-max:flex-col sm-max:flex-col ">
          <div className="scrollbar flex flex-col w-[35%] mr-5 md-max:flex-row sm-max:flex-row sm-max:overflow-x-scroll md-max:overflow-x-scroll md-max:w-full  sm-max:w-full sm-max:mb-6 items-start ">
            {tabData.map((tabName, idx) => (
              <button
                id={`tab-${idx}`}
                key={idx}
                onClick={() => {
                  setTabIndex(idx);
                  if (window.innerWidth <= 1024) {
                    document.querySelector(`#tab-${idx}`).scrollIntoView({
                      behavior: 'smooth',
                      inline: tabData.length - 1 == idx ? "end" : "center"
                    });
                  }
                }}
                className={`
                  flex justify-center text-2xl py-[5px] text-[#6666668d] pl-3 border-l-2 border-[#666666] min-w-max outline-none  md-max:border-l-0 sm-max:border-l-0 sm-max:border-b-2 sm-max:px-5 sm-max:pl-0
                  ${idx === tabIndex ? "!text-[#3976f9] font-bold border-l-2 !border-[#3976f9] " : ""}
              `}
              >
                <p>{tabName}</p>
              </button>


            ))}
            <YourResultSection item={item} />
          </div>

          <div className="mt-2  w-full">
            {tabIndex == 0 && (
              <div>
                <div>
                  <p className="font-bold text-[3rem] text-[#3976f9] mb-[3rem] sm-max:hidden">
                    Introduction
                  </p>
                  {getArrayParaValues(item?.introduction?.toString())}
                  {/* <div className="grid grid-cols-3 gap-[3.125rem] py-[5rem] px-6 sm-max:grid-cols-1 sm-max:px-0 sm-max:py-[2rem] sm-max:gap-4">
                    <div className="bg-[#CBCBCB33]  sm-max:py-6 flex items-center justify-center flex-col rounded-2xl">
                      <p className="text-[#3976f9] font-bold text-[2.5rem] sm-max:text-2xl">
                        8.2%
                      </p>
                      <p className="text-xl sm-max:text-base ">
                        of the population
                      </p>
                    </div>
                    <div className="bg-[#CBCBCB33] py-8 sm-max:py-6 flex items-center justify-center flex-col rounded-2xl">
                      <p className="text-[#3976f9] font-bold text-[2.5rem] sm-max:text-2xl">
                        5.8%
                      </p>
                      <p className="text-xl sm-max:text-base">of men</p>
                    </div>
                    <div className="bg-[#CBCBCB33]  sm-max:py-6 flex items-center justify-center flex-col rounded-2xl">
                      <p className="text-[#3976f9] font-bold text-[2.5rem] sm-max:text-2xl">
                        10.2%
                      </p>
                      <p className="text-xl sm-max:text-base">of women</p>
                    </div>
                  </div> */}
                  {item.introduction && getArrayPointValues(item?.introduction?.toString())}
                </div>
              </div>
            )}

            {tabIndex == 1 && (
              <div>
                <div>
                  <p className="font-bold text-[3rem] text-[#3976f9] mb-[3rem] sm-max:hidden">
                    Strength and Weakness
                  </p>
                  <div>
                    <p className="text-2xl font-bold mb-9 ">Strength:</p>
                    {item.strength && getArrayValues(item?.strength?.toString())}
                    {/* <div className="bg-[#EDEDED] py-10 px-12 my-20 border-l-4 border-[#20AE51] sm-max:px-6 sm-max:py-5 sm-max:my-10">
                      <p className="text-xl sm-max:text-base">
                        Lorem ipsum dolor sit amet consectetur. Ullamcorper a in
                        lorem id. Suspendisse nam nec ut urna id aliquet turpis.
                        Id tincidunt ut pellentesque nunc non. Molestie magna a
                        et tempus bibendum sed.
                      </p>
                    </div> */}
                    <p className="text-2xl font-bold mb-9 ">Weakness:</p>
                    {item.weakness && getArrayValues(item?.weakness?.toString())}
                  </div>
                </div>
              </div>
            )}
            {tabIndex == 2 && (
              <div>
                <div>
                  <p className="font-bold text-[3rem] text-[#3976f9] mb-[3rem] sm-max:hidden">
                    Career Path
                  </p>
                  <div className=" flex items-center sm-max:flex-col sm-max:items-start ">
                    <div>
                      <p className="text-[30px] font-bold text-[#3976f9] sm-max:text-xl ">
                        Wow! you are a natural,
                      </p>
                      <p className="text-[30px] sm-max:text-xl">
                        Here is where you could shine.
                      </p>
                    </div>
                    <div>
                      <ResultChart item={item} />
                    </div>
                  </div>
                  {/* <div className="flex gap-[5.625rem] py-[5rem] sm-max:flex-col sm-max:gap-14">
                    <div className="flex flex-col gap-[2rem]">
                      <p className="text-xl font-bold sm-max:text-xl">
                        100% Social
                      </p>
                      <p className="text-xl sm-max:text-base">
                        People like to work with “people” who “seem to satisfy
                        their needs in teaching or helping situations.” They
                        tend to be “drawn more to seek close relationships with
                        other people and are less apt to want to be intellectual
                        or physical.” Sample majors and careers include:
                      </p>
                      <div className="flex flex-col gap-4">
                        <div className="flex gap-1 items-center">
                          <span>
                            <GoDotFill size={12} />
                          </span>
                          <p className="text-xl sm-max:text-base">
                            Archivist/Librarian
                          </p>
                        </div>
                        <div className="flex gap-1 items-center">
                          <span>
                            <GoDotFill size={12} />
                          </span>
                          <p className="text-xl sm-max:text-base">
                            Community Organizer
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-col gap-[2rem]">
                      <p className="text-xl font-bold sm-max:text-xl">
                        75% Artistic Attribute
                      </p>
                      <p className="text-xl sm-max:text-base">
                        People prefer “to think and observe rather than act” and
                        “to organize and understand information rather than to
                        persuade.” They are also drawn to working with “data”
                        over working with “people.” Sample majors and careers
                        include:
                      </p>
                      <div className="flex flex-col gap-4">
                        <div className="flex gap-1 items-center">
                          <span>
                            <GoDotFill size={12} />
                          </span>
                          <p className="text-xl sm-max:text-base">Actuary</p>
                        </div>
                        <div className="flex gap-1 items-center">
                          <span>
                            <GoDotFill size={12} />
                          </span>
                          <p className="text-xl sm-max:text-base">Archivist</p>
                        </div>
                      </div>
                    </div>
                  </div> */}
                  <p className="text-[20px] mb-10 sm-max:text-base">
                    Though these are most prominent fields, you could also shine
                    in fields such as:
                  </p>
                  {item.careerPath && getArrayValues(item?.careerPath?.toString())}
                  {/* <div className="bg-[#EDEDED] py-10 px-12 my-20 border-l-4 border-[#20AE51]  sm-max:px-6 sm-max:py-5 sm-max:my-10">
                    <p className="text-[20px] sm-max:text-base">
                      Lorem ipsum dolor sit amet consectetur. Ullamcorper a in
                      lorem id. Suspendisse nam nec ut urna id aliquet turpis.
                      Id tincidunt ut pellentesque nunc non. Molestie magna a et
                      tempus bibendum sed.
                    </p>
                  </div> */}
                </div>
              </div>
            )}
            {tabIndex == 3 && (
              <div>
                <div>
                  <p className="font-bold text-[3rem] text-[#3976f9] mb-[3rem] sm-max:hidden">
                    Workplace Habit
                  </p>
                  {/* <div className="bg-[#EDEDED] py-10 px-12 my-20 border-l-4 border-[#20AE51] sm-max:px-6 sm-max:py-5 sm-max:my-0">
                    <p className="text-[20px] sm-max:text-base">
                      Lorem ipsum dolor sit amet consectetur. Ullamcorper a in
                      lorem id. Suspendisse nam nec ut urna id aliquet turpis.
                      Id tincidunt ut pellentesque nunc non. Molestie magna a et
                      tempus bibendum sed.
                    </p>
                  </div> */}
                  {item.workplaceHabit && getArrayValues(item?.workplaceHabit?.toString())}
                </div>
              </div>
            )}
            {tabIndex == 4 && (
              <div>
                {<UserReview setVisible={setPopupView} visible={popupView} />}
                <div>
                  <p className="font-bold text-[3rem] text-[#3976f9] mb-[3rem] sm-max:hidden">
                    Conclusion
                  </p>
                  {item?.conclusion && item?.conclusion?.toString()?.split("\\n").map((data, index) => (
                    <>
                      <p className="text-xl sm-max:text-base">{data}</p>
                      {item?.conclusion?.split("\\n").length !== index && <br />}
                    </>
                  ))}

                  {/* <div className="bg-[#EDEDED] py-10 px-12 my-20 border-l-4 border-[#20AE51] sm-max:px-6 sm-max:py-5 sm-max:my-10">
                    <p className="text-[20px] sm-max:text-base">
                      Lorem ipsum dolor sit amet consectetur. Ullamcorper a in
                      lorem id. Suspendisse nam nec ut urna id aliquet turpis.
                      Id tincidunt ut pellentesque nunc non. Molestie magna a et
                      tempus bibendum sed.
                    </p>
                  </div> */}
                </div>
              </div>
            )}
            {tabIndex == 5 && (
              <div>
                <div>
                  <p className="font-bold text-[3rem] text-[#3976f9] mb-[3rem] sm-max:hidden">
                    Celebrity
                  </p>
                  <div className="grid grid-cols-3 gap-5 sm-max:grid-cols-1">
                    {item?.celebrity && item.celebrity.map((data) => (
                      <div>
                        <img
                          src={data.imgUrl}
                          alt="img"
                          className="rounded-t-2xl w-full h-48 object-cover"
                        />
                        <div className="bg-[#CBCBCB33] px-6 py-5 rounded-b-2xl">
                          <p className="text-2xl font-bold">{data.name}</p>
                          <p>{data.title}</p>
                        </div>
                      </div>
                    ))}
                  </div>
                  {/* <div className="relative bg-[#EDEDED] py-10 px-12 my-20 border-l-4 border-[#20AE51] sm-max:hidden">
                    <p className="text-[20px]">
                      Lorem ipsum dolor sit amet consectetur. Ullamcorper a in
                      lorem id. Suspendisse nam nec ut urna id aliquet turpis.
                      Id tincidunt ut pellentesque nunc non. Molestie magna a et
                      tempus bibendum sed.
                    </p>
                    <p className="italic text-xl mt-5">~ Neymar Jr</p>
                    <p className="absolute right-6 top-6">
                      <svg
                        width="25"
                        height="20"
                        viewBox="0 0 25 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M10.6079 13.778C10.6079 10.0061 9.36279 6.54538 6.87256 3.39596C5.29785 1.49167 4.03442 0.667691 3.08228 0.924039C2.20337 1.25363 1.76392 1.74801 1.76392 2.40719C1.76392 3.02975 2.05688 3.68893 2.64282 4.38473C3.19214 5.08053 3.63159 5.64816 3.96118 6.08761C4.29077 6.52707 4.56543 7.00314 4.78516 7.51584C5.29785 8.54123 5.5542 9.78634 5.5542 11.2512C4.67529 10.9948 3.79639 11.1047 2.91748 11.5808C1.30615 12.4963 0.500488 13.6682 0.500488 15.0964C0.500488 16.488 0.939941 17.6233 1.81885 18.5022C2.66113 19.3444 3.83301 19.7656 5.33447 19.7656C6.83594 19.7656 8.09937 19.198 9.12476 18.0627C10.1135 16.8908 10.6079 15.4626 10.6079 13.778ZM24.8901 13.778C24.8901 9.85959 23.6633 6.39889 21.2097 3.39596C19.342 1.16208 17.7856 0.484586 16.5405 1.36349C16.2842 1.5466 16.156 1.83957 16.156 2.2424C16.156 2.97482 16.4307 3.68893 16.98 4.38473C17.4927 5.08053 17.9138 5.64816 18.2434 6.08761C18.573 6.52707 18.8477 7.00314 19.0674 7.51584C19.5801 8.54123 19.8364 9.78634 19.8364 11.2512C18.9575 10.9948 18.0969 11.1047 17.2546 11.5808C15.6799 12.4963 14.8926 13.6682 14.8926 15.0964C14.8926 16.488 15.3137 17.6233 16.156 18.5022C16.9983 19.3444 18.1702 19.7656 19.6716 19.7656C21.1731 19.7656 22.4182 19.198 23.407 18.0627C24.3958 16.8908 24.8901 15.4626 24.8901 13.778Z"
                          fill="#20AE51"
                        />
                      </svg>
                    </p>
                  </div> */}
                </div>
              </div>
            )}
            <div className="py-10">
            </div>
            <div className="flex justify-between">
              <div>
                {tabIndex > 0 && (
                  <button
                    className="py-4 px-8 text-2xl flex gap-1 items-center sm-max:py-2 sm-max:text-xl"
                    onClick={() => {
                      setTabIndex(tabIndex - 1);
                      document.querySelector(`#tab-${tabIndex - 1}`).scrollIntoView({
                        behavior: "smooth",
                        inline: tabData.length - 1 == tabIndex - 1 ? "end" : "center"
                      });
                    }}
                  >
                    <FiChevronLeft /> Back
                  </button>
                )}
              </div>
              <div>
                {tabIndex < 5 && (
                  <button
                    className="bg-[#1C75BC] py-4 px-8 text-white rounded-lg text-2xl flex gap-1 items-center sm-max:py-2 sm-max:text-xl"
                    onClick={() => {
                      setTabIndex(tabIndex + 1);
                      document.querySelector(`#tab-${tabIndex + 1}`).scrollIntoView({
                        behavior: "smooth",
                        inline: tabData.length - 1 == tabIndex + 1 ? "end" : "center"
                      });
                    }}
                  >
                    Next <FiChevronRight />
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* </div> */}
    </div>
  );
};

export default DetailResultTabSection;
