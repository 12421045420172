import React, { useState } from "react";
import { Accordion, AccordionTab } from "primereact/accordion";

const FAQ = () => {
  // Manage the expanded state for each tab
  const [activeIndex, setActiveIndex] = useState(null);

  // Toggle the active state
  const toggleTab = (index) => {
    setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <div className="flex lg-small:flex-col justify-between mt-16 px-20 lg-small:gap-10">
      <div className="sm:lg-small:text-center sm-max:text-left">
        <h1 className="text-4xl font-medium">Frequently Asked Questions</h1>
      </div>
      <div className="w-1/2 lg-small:w-full">
        <Accordion activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)} className="flex flex-col gap-4">
          <AccordionTab className={`[&>a]:bg-white [&>a>svg]:hidden [&>a]:rounded-t-lg [&>a]:border-t [&>div]:rounded-b-lg [&>a]:border-x [&>div]:border-x [&>div]:border-b [&>*]:border-[#DFE1E6] [&>a]:py-4 [&>div]:pt-0 ${activeIndex !== 0 ? '[&>a]:rounded-b-lg [&>a]:border-b' : ''}`}
            header={
              <div className="flex items-center w-full ">
                <span className="font-medium white-space-nowrap">What is Genesis?</span>
                <button
                  className="text-lg font-bold ml-auto"
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleTab(0);
                  }}
                >
                  {activeIndex === 0 ? '−' : '+'}
                </button>
              </div>
            }
          >
            <p className="m-0">
              Ultrices nulla lacus nibh mauris tempor velit eleifend amet. Eu in sit volutpat laoreet ornare eget lectus nibh elit. Morbi enim eu id arcu facilisi libero aliquam condimentum aliquet. Feugiat dui nisl varius vitae. Urna dis neque blandit mauris hac id. Eu commodo a velit pellentesque diam vitae bibendum. Nunc ut risus sagittis interdum blandit ac quis pulvinar morbi
            </p>
          </AccordionTab>
          <AccordionTab className={`[&>a]:bg-white [&>a>svg]:hidden [&>a]:rounded-t-lg [&>a]:border-t [&>div]:rounded-b-lg [&>a]:border-x [&>div]:border-x [&>div]:border-b [&>*]:border-[#DFE1E6] [&>a]:py-4 [&>div]:pt-0 ${activeIndex !== 1 ? '[&>a]:rounded-b-lg [&>a]:border-b' : ''}`}
            header={
              <div className="flex items-center w-full ">
                <span className="font-semibold white-space-nowrap">What is Genesis?</span>
                <button
                  className="text-lg font-bold ml-auto"
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleTab(1);
                  }}
                >
                  {activeIndex === 1 ? '−' : '+'}
                </button>
              </div>
            }
          >
            <p className="m-0">
              Ultrices nulla lacus nibh mauris tempor velit eleifend amet. Eu in sit volutpat laoreet ornare eget lectus nibh elit. Morbi enim eu id arcu facilisi libero aliquam condimentum aliquet. Feugiat dui nisl varius vitae. Urna dis neque blandit mauris hac id. Eu commodo a velit pellentesque diam vitae bibendum. Nunc ut risus sagittis interdum blandit ac quis pulvinar morbi
            </p>
          </AccordionTab>
          <AccordionTab className={`[&>a]:bg-white [&>a>svg]:hidden [&>a]:rounded-t-lg [&>a]:border-t [&>div]:rounded-b-lg [&>a]:border-x [&>div]:border-x [&>div]:border-b [&>*]:border-[#DFE1E6] [&>a]:py-4 [&>div]:pt-0 transition-all duration-1000 ${activeIndex !== 2 ? '[&>a]:rounded-b-lg [&>a]:border-b' : ''}`}
            header={
              <div className="flex items-center w-full ">
                <span className="font-semibold white-space-nowrap">What is Genesis?</span>
                <button
                  className="text-lg font-bold ml-auto"
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleTab(2);
                  }}
                >
                  {activeIndex === 2 ? '−' : '+'}
                </button>
              </div>
            }
          >
            <p className="m-0">
              Ultrices nulla lacus nibh mauris tempor velit eleifend amet. Eu in sit volutpat laoreet ornare eget lectus nibh elit. Morbi enim eu id arcu facilisi libero aliquam condimentum aliquet. Feugiat dui nisl varius vitae. Urna dis neque blandit mauris hac id. Eu commodo a velit pellentesque diam vitae bibendum. Nunc ut risus sagittis interdum blandit ac quis pulvinar morbi
            </p>
          </AccordionTab>
          <AccordionTab className={`[&>a]:bg-white [&>a>svg]:hidden [&>a]:rounded-t-lg [&>a]:border-t [&>div]:rounded-b-lg [&>a]:border-x [&>div]:border-x [&>div]:border-b [&>*]:border-[#DFE1E6] [&>a]:py-4 [&>div]:pt-0 ${activeIndex !== 3 ? '[&>a]:rounded-b-lg [&>a]:border-b' : ''}`}
            header={
              <div className="flex items-center w-full ">
                <span className="font-semibold white-space-nowrap">What is Genesis?</span>
                <button
                  className="text-lg font-bold ml-auto"
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleTab(3);
                  }}
                >
                  {activeIndex === 3 ? '−' : '+'}
                </button>
              </div>
            }
          >
            <p className="m-0">
              Ultrices nulla lacus nibh mauris tempor velit eleifend amet. Eu in sit volutpat laoreet ornare eget lectus nibh elit. Morbi enim eu id arcu facilisi libero aliquam condimentum aliquet. Feugiat dui nisl varius vitae. Urna dis neque blandit mauris hac id. Eu commodo a velit pellentesque diam vitae bibendum. Nunc ut risus sagittis interdum blandit ac quis pulvinar morbi
            </p>
          </AccordionTab>

        </Accordion>
      </div>
    </div>
  );
};

export default FAQ;
