import { useRef } from "react";
import { FaBars, FaTimes, FaUserCircle } from "react-icons/fa";
import "./Lheader.css";
import logo from "../../Assets/my-aptitude-ai-uslot.png";
import { Button } from "primereact/button";
import { RiArrowRightLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import Cookies from 'js-cookie';
import config from '../../config.json';
import { Link } from "react-router-dom";
import { CiLogout } from "react-icons/ci";

function Lheader() {
  const navRef = useRef();
  const navigate = useNavigate();
  const usernameCookie = Cookies.get('username', { domain: config.cookieDomain });
  const profileImage = Cookies.get('picture', { domain: config.cookieDomain });
  const tokenCookie = Cookies.get('token', { domain: config.cookieDomain });
  const isMobile = window.innerWidth < 768;

  const showNavbar = () => {
    navRef.current.classList.toggle("responsive_nav");
  };

  return (
    <div className="header-main">
      <header>
        <Link to={"/"}><img className="header-logo object-cover" src={logo} alt="header-logo" /></Link>
        <div className="navs">
          <nav ref={navRef}>
            <a href="/#" onClick={() => isMobile && showNavbar()}>Home</a>
            <a href="/#features" onClick={() => isMobile && showNavbar()}>Features</a>
            <a href="/#review" onClick={() => isMobile && showNavbar()}>Reviews</a>
            {tokenCookie &&
              <a onClick={() => isMobile && [showNavbar(), navigate('/logout')]}>Logout</a>
            }

            <button className="nav-btn nav-close-btn" onClick={showNavbar}>
              <FaTimes />
            </button>
          </nav>
        </div>

        <button className="nav-btn" onClick={showNavbar}>
          <FaBars />
        </button>

        {!tokenCookie ?
          <Button onClick={() => navigate('/login')} className="header-login-link">
            Login/Signup <RiArrowRightLine />
          </Button>
          :
          <div className="flex gap-10 sm-max:hidden">
            <div className="profile-buttons" onClick={() => tokenCookie && navigate('/my_profile')}>
              <button >
                Hello {usernameCookie && usernameCookie.split('-').join(' ')}
                {profileImage ?
                  <img src={profileImage} alt="profile-img" width='38px' height='38px' style={{ borderRadius: '50%' }} />
                  :
                  <FaUserCircle size={38} color="#1C75BC" />
                }
              </button>
            </div>
            <div
              onClick={() => navigate('/logout')}
              className="quiz-headerprofile-buttons" style={{ gap: '5px' }}>
              <button>
                Logout
                <CiLogout size={38} color="#1C75BC" />
              </button>
            </div>
          </div>
        }
      </header>
    </div>
  );
}

export default Lheader;