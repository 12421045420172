import React from 'react'
import { GoDotFill } from "react-icons/go";
import Layout from '../Layout/Layout';
import './CancellationRefund.css'
const CancellationRefund = () => {
    return (
        <Layout>
            <div className="cancellation-refund px-[6rem] py-[5rem] flex flex-col gap-10 md-max:px-[2rem] sm-max:px-[1rem] sm-max:py-[2rem] sm-max:gap-5">
                <p>At USlot.ai, we strive to provide valuable career prediction and guidance services to our users. If for any reason you are not satisfied with our services, please review our cancellation and refund policy below.</p>
                <div className='contents'>
                    <p className='sub-heading'>1. Cancellation</p>
                    <p className='point '><span><GoDotFill /></span>Subscription Services: If you have subscribed to any premium services or subscription plans on USlot.ai, you may cancel your subscription at any time by accessing your account settings and following the cancellation instructions. Upon cancellation, you will continue to have access to the premium features until the end of your current billing cycle, after which your subscription will not renew.</p>
                    <div className='flex items-start'>
                        <span><GoDotFill /></span>
                        <p className=' '>Single Purchases: For one-time purchases of premium features or services, you may cancel your purchase within a specified cancellation period, typically within 14 days of the purchase date. To cancel a single purchase, please contact our customer support team at <a className="text-blue-500 " href="mailto:uslotedu@gmail.com">uslotedu@gmail.com</a> and provide your purchase details.</p>
                    </div>
                </div>
                <div className='contents'>
                    <p className='sub-heading'>2. Refund Policy</p>
                    <p className='point'><span><GoDotFill /></span>Subscription Services: We offer a satisfaction guarantee for our subscription services. If you are not satisfied with your subscription within the first 30 days of purchase, you may request a full refund. Refund requests made after the initial 30-day period will be considered on a case-by-case basis.</p>
                    <p className='point'><span><GoDotFill /></span>Single Purchases: Refunds for one-time purchases are subject to our refund policy and may vary depending on the circumstances. Generally, refunds for single purchases will be provided if the request is made within the specified cancellation period and the purchased feature or service has not been substantially utilized.</p>
                </div>
                <div className='contents'>
                    <p className='sub-heading'>3. How to Request a Refund</p>
                    <p className='font-semibold'>To request a refund, please contact our customer support team at contact@uslot.ai and provide the following information:</p>
                    <div className='px-5'>
                        <p className='point'><span><GoDotFill /></span>Your name and email address associated with your USlot.ai account.</p>
                        <p className='point'><span><GoDotFill /></span>Details of your purchase, including the date of purchase and the purchased feature or service.</p>
                        <p className='point'><span><GoDotFill /></span>Reason for the refund request.</p>
                    </div>
                    <p>Our customer support team will review your request and process the refund according to our refund policy.</p>
                </div>
                <div className='contents'>
                    <p className='sub-heading'>4. Contact Us</p>
                    <p>If you have any questions or concerns about our cancellation and refund policy, please contact us at <a className="text-blue-500" href="mailto:uslotedu@gmail.com">uslotedu@gmail.com</a></p>
                    <p>Thank you for choosing USlot.ai. We value your satisfaction and are committed to providing you with exceptional service and support.</p>
                </div>
            </div>
        </Layout>
    )
}

export default CancellationRefund