import React from "react";
import oneToOne from "../../Assets/one-to-one.png";
const DetailResultBottomSection = () => {
  return (
    <div>
      <div className="flex md-max:flex-col sm-max:flex-col sm-max:px-4 sm-max:py-4">
        <img src={oneToOne} alt="img" className="rounded-tl-[2.25rem] rounded-bl-[2.25rem] sm-max:rounded-tl-[2.25rem] sm-max:rounded-tr-[2.25rem] sm-max:rounded-bl-[0rem] sm-max:rounded-br-[0rem] md-max:rounded-tr-[2.25rem] md-max:rounded-bl-[0rem]" />
        <div className="bg-white p-[3rem] sm-max:rounded-bl-[2.25rem] sm-max:rounded-br-[2.25rem] rounded-r-[2.25rem] flex flex-col justify-center gap-[2rem] sm-max:p-3 sm-max:rounded-r-[0rem] sm-max:rounded-b-[2.25rem] sm-max:gap-[1rem] md-max:rounded-tr-[0rem] md-max:rounded-bl-[2.25rem]">
          <p className="text-2xl font-bold">One-On-One learning</p>
          <p className="text-[22px] text-[#9CA3AF] sm-max:text-base">
            One-On-One learning, as offered by USLOT through our Tutefriends, is
            an educational approach where a single tutor focuses their entire
            attention on one student at a time. This contrasts with traditional
            classroom settings where a teacher has to manage a group of students
            simultaneously.
          </p>
          <div>
            <button className="bg-[#1C75BC] py-3 px-6 text-white font-medium rounded-lg">
              <a href="https://uslotedu.com" target="_blank" rel="noopener noreferrer"> Explore academic Tutoring</a>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailResultBottomSection;
